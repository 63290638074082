import React, { MutableRefObject } from 'react';

import { Language, Video } from '../domain/types';
import Button from './Button';

import { CustomEvent } from '@piwikpro/react-piwik-pro';

interface Props {
  video: Video;
  lang: Language;
  email: string;
}

const FeaturedVideo: React.FC<Props> = ({
  video,
  lang,
  email,
}) => {

  let ref = React.useRef<HTMLVideoElement | null>(null);
  const [buttonVisible, setButtonVisible] = React.useState(true);
  const [videoReady, setVideoReady] = React.useState(false);
  const [src, setSrc] = React.useState(video.url_en);

  React.useEffect(() => {
    setSrc(getSource());
  }, [video, lang]);

  const getSource = () => {
    switch (lang) {
      case 'en':
        return video.url_en;

      case 'nl':
        return video.url_nl;

      case 'fr':
        return video.url_fr;

      default:
        return video.url_en;
    };
  }

  const play = () => {
    ref.current?.play();
    setButtonVisible(false);
    if (ref.current?.requestFullscreen) {
      ref.current?.requestFullscreen();
    // @ts-ignore
    } else if (ref.current?.webkitRequestFullscreen) {
    // @ts-ignore
      ref.current?.webkitRequestFullscreen();
    // @ts-ignore
    } else if (ref.current?.mozRequestFullScreen) {
    // @ts-ignore
      ref.current?.mozRequestFullScreen();
    // @ts-ignore
    } else if (ref.current?.msRequestFullscreen) {
    // @ts-ignore
      ref.current?.msRequestFullscreen();
    }
  }; 

  const onVideoPlay = () => {
    try {
      CustomEvent.trackEvent('alamiretv', 'video-play', src);
      CustomEvent.trackEvent('alamiretv', 'video-watched-by', email);

    } catch (error) {
      // ignore error
    }
  };

  React.useEffect(() => {

    if (ref.current && ref.current.readyState !== 4) {
      console.log('loading video');
      // ref.current.re
      ref.current.load();
      setButtonVisible(true);
    } else if (ref.current) {
      console.log('video ready');
      setVideoReady(true);
    }
  }, [video, lang, ref.current]);

  return (
    <div style={{position: 'relative'}}>  
      <video
        id="fullscreen-vid"
        ref={ref}
        key={`${video.title_nl}-${lang}`}
        style={{width: '100%', objectFit: 'contain'}}
        poster={video.placeholder_image}
        controls
        onContextMenu={() => false}
        controlsList="nodownload"
        preload='none'
        onPause={() => setButtonVisible(true)}
        onPlay={onVideoPlay}
        onLoadedData={() => setVideoReady(true)}
      >
        <source src={src} type="video/mp4"/>
      </video>
      {buttonVisible ? 
      <div style={{position: 'absolute', top: '70%', right: '10%', zIndex: 100}}>
        <Button
          text="PLAY"
          onClick={play}
          large
          // disabled={!videoReady}
        />
      </div> : null}
    </div>
  );
};

export default FeaturedVideo;