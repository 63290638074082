import React from 'react';
import Button from './Button';
import Input from './Input';

import i18n from 'i18next';


import Select from 'react-select';

import countries from '../assets/countries.json';
import { Language } from '../domain/types';
import Checkbox from './Checkbox';
import { useTranslate } from '../hooks/useTranslate';

interface Props {
  language: Language,
  login: (address: string) => void;
  register: (address: string, firstName: string, lastName: string, country: string, hasEn: boolean, hasDutch: boolean) => void;
}

const AuthModal: React.FC<Props> = ({ login, register, language }) => {

  const { t } = useTranslate();

  const [backdrop, setBackdrop] = React.useState(false);

  const [address, setAddress] = React.useState('');

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [registerAddress, setRegisterAddress] = React.useState('');
  const [english, setEnglish] = React.useState(false);
  const [dutch, setDutch] = React.useState(true);
  const [country, setCountry] = React.useState<{value: string, label: string}>({
    value: 'BE',
    label: t('general.belgium')
  });

  const countriesByLanguage = language === 'nl' ? countries.countries_nl : countries.countries_en;

  const options = React.useMemo(() => Object.entries(countriesByLanguage).map(item => {
    return {
      value: item[0],
      label: item[1]
    }
  }), []);

  const onLogin = () => {
    login(address);
  };

  const onRegister = () => {
    console.log('register');
    register(registerAddress, firstName, lastName, country.value, english, dutch);
  };

  const onBackdropClick = () => {
    setBackdrop(true);
    setTimeout(() => {
      setBackdrop(false);
    }, 200);
  }

  return (
    <div
      onClick={onBackdropClick}
      style={{
        position: 'absolute',
        top: 0, width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div 
        onClick={(e) => e.stopPropagation()}
        style={{
          backgroundColor: 'white',
          padding: '1.5rem',
          transform: `scale(${backdrop ? 1.05 : 1})`,
          transition: 'transform 0.2s ease-in-out',
          maxWidth: 700,
          marginLeft: 20,
          marginRight: 20,

        }}
      >
        <h4 style={{margin: 0, color: '#a6361f', fontSize: '1.5rem'}}>alamire.tv</h4>
        <p style={{color: "#666276", lineHeight: 1.4, maxWidth: 600, marginTop: 8}}>
          {t('auth.modal_title')}
        </p>
        <form
          onSubmit={(
            e: React.FormEvent<HTMLFormElement>
          ) => {
            e.preventDefault();
          }}
        >
          <div style={{
            display: 'flex',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: 'lightgray',
            borderStyle: 'solid',
            padding: '0.425rem',
            boxShadow: '0 .125rem .125rem -0.125rem rgba(31,27,45,.08),0 .25rem .75rem rgba(31,27,45,.08)'
          }}>
            <Input
              id="email-login"
              value={address}
              onChange={(value) => setAddress(value)}
              type="email"
              placeholder={t('general.your_email')}
              autoFocus
              required
            />
            <Button
              text={i18n.t('general.login')}
              onClick={onLogin}
            />
          </div>
        </form>
        <p style={{marginBottom: 8, marginTop: 40, fontWeight: 'bold', color: '#666276'}}>
          {t('auth.register_title')}
        </p>
        <form
          onSubmit={(
            e: React.FormEvent<HTMLFormElement>
          ) => {
            e.preventDefault();
          }}
        >
          <div style={{
            display: 'flex',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: 'lightgray',
            borderStyle: 'solid',
            padding: '0.425rem',
            boxShadow: '0 .125rem .125rem -0.125rem rgba(31,27,45,.08),0 .25rem .75rem rgba(31,27,45,.08)',
            marginTop: 0
          }}>
          <Input
            value={firstName}
            onChange={(value) => setFirstName(value)}
            type="text"
            placeholder={t('general.first_name')}
            required
            id="register-first-name"
          />
        </div>
        <div style={{
          display: 'flex',
          borderWidth: 1,
          borderRadius: 4,
          borderColor: 'lightgray',
          borderStyle: 'solid',
          padding: '0.425rem',
          boxShadow: '0 .125rem .125rem -0.125rem rgba(31,27,45,.08),0 .25rem .75rem rgba(31,27,45,.08)',
          marginTop: 16,
          marginBottom: 16
        }}>
          <Input
            value={lastName}
            onChange={(value) => setLastName(value)}
            type="text"
            placeholder={t('general.last_name')}
            required
            id="register-last-name"
          />
        </div>
        <div style={{display: 'flex', gap: 20}}>
          <div style={{flex: 1}}>
            <Checkbox value={dutch} label={t('general.dutch')} toggleValue={() => setDutch(val => !val)} id="checkbox-nl" />
            <Checkbox value={english} label={t('general.english')} toggleValue={() => setEnglish(val => !val)} id="checkbox-en" />
          </div>
          <div style={{flex: 1}}>
            <Select
              options={options}
              value={country}
              onChange={(value) => setCountry(value ?? {value: 'BE', label: t('general.belgium')})}
            />
          </div>
        </div>
        <div style={{
          display: 'flex',
          borderWidth: 1,
          borderRadius: 4,
          borderColor: 'lightgray',
          borderStyle: 'solid',
          padding: '0.425rem',
          boxShadow: '0 .125rem .125rem -0.125rem rgba(31,27,45,.08),0 .25rem .75rem rgba(31,27,45,.08)',
          marginTop: 16
        }}>
          <Input
            value={registerAddress}
            onChange={(value) => setRegisterAddress(value)}
            type="email"
            placeholder={t('general.your_email')}
            required
            id="register-email"
          />
          <Button
            text={t('general.register')}
            onClick={onRegister}
          />
        </div>
      </form>

      </div>
    </div>
  );
};

export default AuthModal;